import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {NameInOther, Severity} from '@wix/wixstores-graphql-schema-node';
import s from './Violations.scss';
import {ViolationNotification} from './ViolationNotification/ViolationNotification';

export enum ViolationsDataHook {
  Root = 'ViolationsDataHook.Root',
}

export const Violations = () => {
  const {
    cartStore: {
      cart: {violations},
    },
  } = useControllerProps();

  const violationsToRender = [
    ...violations.filter(
      (violation) =>
        violation.severity === Severity.ERROR && violation.target?.other?.name === NameInOther.OTHER_DEFAULT
    ),
    ...violations.filter(
      (violation) =>
        violation.severity === Severity.WARNING && violation.target?.other?.name === NameInOther.OTHER_DEFAULT
    ),
  ]
    .slice(0, 3)
    .reverse();

  return (
    violationsToRender.length > 0 && (
      <div data-hook={ViolationsDataHook.Root}>
        {violationsToRender.map((violation, index) => {
          return (
            <div key={index} className={s.notification}>
              <ViolationNotification violation={violation} />
            </div>
          );
        })}
      </div>
    )
  );
};
