import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../ControllerContext';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {ShippingDestination} from '../ShippingDestination/ShippingDestination';
import {Tooltip} from 'wix-ui-tpa';
import s from './EstimatedTax.scss';

const ShippingDestinationAndTaxRates = () => {
  const {t} = useTranslation();
  const {shouldDisplayEstimatedTaxShippingDestination, taxName} = useControllerProps().orderStore;
  const {formattedTax} = useControllerProps().cartStore.cart.convertedTotals;

  return (
    <dl>
      <dt>
        <div data-hook={OrderSummaryDataHook.EstimatedTaxShippingDestinationWithTitle}>
          <span data-hook={OrderSummaryDataHook.EstimatedTaxTaxName}>
            {taxName || /* istanbul ignore next */ t('cart.estimated_tax')}
          </span>
          &nbsp;
          <Tooltip content={t('cart.tooltips.estimated_tax_message')} placement="top" minWidth={200} maxWidth={250}>
            <span className={s.info}>?</span>
          </Tooltip>
          {shouldDisplayEstimatedTaxShippingDestination && (
            <ShippingDestination dataHook={OrderSummaryDataHook.ShippingDestinationTaxRoot} />
          )}
        </div>
      </dt>
      <dd data-hook={OrderSummaryDataHook.EstimatedTaxEstimatedTaxPrice}>{formattedTax}</dd>
    </dl>
  );
};

export const EstimatedTax = () => {
  const {isEstimatedDestinationIsShippable, shouldDisplayEstimatedTaxShippingDestination} =
    useControllerProps().orderStore;

  return (
    <div data-hook={OrderSummaryDataHook.EstimatedTaxRoot}>
      {isEstimatedDestinationIsShippable ? (
        <ShippingDestinationAndTaxRates />
      ) : (
        shouldDisplayEstimatedTaxShippingDestination && (
          <dt>
            <ShippingDestination dataHook={OrderSummaryDataHook.ShippingDestinationTaxRoot} />
          </dt>
        )
      )}
    </div>
  );
};
